<template>
  <v-snackbar
      v-model="pop.value"
      :top="pop.top"
      :color="pop.color"
      :left="pop.left"
      :multi-line="pop.multiLine"
      :right="pop.right"
      :timeout="pop.timeout===0 ? 0 :(pop.timeout || 3000)"
      :vertical="pop.vertical"
      style="z-app: 10000000"
  >
    {{ pop.text }}
    <v-btn
        class="float-right"
        dark
        icon
        x-small
        @click="pop.value = false"
    >
      <span class="headline font-weight-light">×</span>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "Tip",
  props: {
    pop: {
      type: Object,
      default: function () {
        return {
          value: false,
          text: 'text area',
          top: false,
          color: 'success',
          left: false,
          multiLine: false,
          right: true,
          timeout: 3000,
          vertical: false
        }
      }
    }
  },
}
</script>

<style scoped>

</style>